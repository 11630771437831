<template>
  <Modal>
    <template v-slot:headline>CSVダウンロード</template>
    <template v-slot:body>
      <p class="description-csv">
        CSVファイルの準備が完了しました。<br />
        ［ダウンロード］ボタンよりダウンロードできます。
      </p>
      <ul class="listGrid justify-content-center">
        <li class="listGrid-item">
          <button
            class="btn btn-lg btn-white"
            type="button"
            @click="cancel"
          >
            閉じる
          </button>
        </li>
        <li class="listGrid-item">
          <ActionButton
            class="btn btn-lg btn-red"
            :handle-submit="download"
            button-text="ダウンロード"
          />
        </li>
      </ul>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal.vue';
import modal from '@/mixins/plugin/modal';

export default {
  components: {
    Modal,
  },
  mixins: [modal],
  methods: {
    cancel() {
      this.$emit('cancelDownloadCsv');
    },
    download() {
      this.$emit('acceptDownloadCsv');
    },
  }
}
</script>
